.button {
    --primary: var(--background-color);
    --secondary: var(--dark-grey);
    width: 100%;
    max-width: 274px;
    max-height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: var(--primary);
    color: var(--text-color, var(--secondary));
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 0.875em;
    aspect-ratio: 3/1;
    transition: 0.3s;

    &:active {
        background: var(--secondary);
        color: var(--text-color, --primary);
    }

    &.grey {
        --primary: var(--transparent-black-10);
        --secondary: var(--transparent-black-20);
        --text-color: var(--black);
    }

    &.border {
        border: 1px solid var(--dark-grey);
    }

    &.facebook {
        --primary: var(--blue);
        --secondary: var(--background-color);
    }

    &.facebook.border {
        border: 1px solid var(--blue);
    }

    &.inverse {
        --primary: var(--dark-grey);
        --secondary: var(--background-color);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@media (max-width: 900px) {
    .button {
        gap: 5px;
    }
}

@media (hover: hover) {
    .button:hover {
        background: var(--secondary);
        color: var(--text-color, var(--primary));
    }
}
