.header-section {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background: linear-gradient(
            var(--transparent-black-20),
            var(--transparent-black-20)
        ),
        url("../../../../assets/first-section-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: calc(var(--offset) * 1.5) var(--offset);
        box-sizing: border-box;

        p {
            max-width: 1105px;
        }

        &-options {
            display: flex;
            gap: 50px;
        }

        &-option {
            color: var(--white);

            .small {
                opacity: 0.6;
            }
        }
    }
}

@media (max-width: 900px) {
    .header-section-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
}
