.banners {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    aspect-ratio: 16/9;

    .swiper {
        width: 100%;
        overflow: unset;

        &-slide {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
