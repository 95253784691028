.doors {
    &-categories {
        width: 100%;
        display: grid;
        padding: 0 var(--offset);
        box-sizing: border-box;
        gap: 50px;
        grid-column-end: 3;
        grid-row-end: 2;
    }

    &-category {
        max-width: 100%;
        max-height: 452px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(1) {
            grid-column: 1;
            grid-row: 1;
        }

        &:nth-child(2) {
            grid-column: 2;
            grid-row: 1;
        }

        &:nth-child(3) {
            grid-column: 1;
            grid-row: 2;
        }

        &:nth-child(4) {
            grid-column: 3;
            grid-row: 2;
        }

        &-image {
            max-width: 100%;
            height: 90%;
            max-height: 319.24px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 20px;
            box-sizing: border-box;
            border: 1px solid var(--transparent-black-10);

            img {
                max-width: 100%;
                max-height: 100%;
                aspect-ratio: 1/2;
            }
        }
    }
}

@media (min-width: 841px) and (max-width: 1360px) {
    .doors {
        &-categories {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 50px;
        }

        &-category {
            width: 300px;
        }
    }
}

@media (max-width: 840px) {
    .doors {
        &-categories {
            display: flex;
            flex-wrap: wrap;
        }

        &-category {
            aspect-ratio: unset;
        }
    }
}

@media (max-width: 600px) {
    .doors {
        &-categories {
            padding: 0 20px;
        }

        &-category,
        &-category-image {
            padding: 10px;
        }
    }
}
