.door-card {
    position: relative;
    width: 100%;
    max-width: 296px;
    max-height: 563px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 3fr 1fr 1fr;
    border: 1px solid var(--transparent-black-10);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    gap: 20px;
    aspect-ratio: 296/563;

    p {
        cursor: auto;

        &.name {
            width: 100%;
            height: max-content;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            white-space: nowrap;
            grid-row: 2;
        }

        &.price {
            width: 100%;
            height: 100%;
            font-size: 30px;
            text-align: center;
            overflow-wrap: anywhere;
            grid-row: 3;
        }

        &.sku {
            max-width: 70%;
            position: absolute;
            top: 5px;
            right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .model {
        color: var(--transparent-black-10);
    }

    .tags {
        width: 100%;
        height: 75px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: auto;
        cursor: auto;
        gap: 20px;
        user-select: none;
        grid-row: 4;

        p {
            height: max-content;
            color: var(--black);
            font-size: 0.8125em;
            background: var(--transparent-black-10);
            padding: 5px 10px;
            border-radius: 6px;
        }
    }

    &-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        aspect-ratio: 1/1;
    }

    img {
        max-width: 90%;
        max-height: 90%;
    }

    button {
        grid-row: 5;
    }
}

@media (max-width: 1599px) {
    .door-card {
        max-width: 240px;
    }
}
