.filters {
    &-container {
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-button {
        position: fixed;
        top: 100px;
        left: 0;
        width: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        background: var(--transparent-black-10);
        border-radius: 0 6px 6px 0;
        cursor: pointer;
        z-index: 3;

        svg {
            transform: rotate(90deg);
            transition: 0.3s;
        }

        &.active {
            left: unset;
            right: 0;
            border-radius: 6px 0 0 6px;

            svg {
                transform: rotate(-90deg);
            }
        }
    }

    &-price {
        width: 100%;
        max-width: 226px;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        gap: 25px;

        &-inputs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                max-width: 76px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border-radius: 6px;
                color: var(--transparent-black-60);
                border: 1px solid var(--transparent-black-10);
                aspect-ratio: 16/9;
                padding: 10px 5px;
                box-sizing: border-box;
                text-align: center;

                &::placeholder {
                    text-align: center;
                    color: var(--transparent-black-60);
                }
            }

            span {
                width: 10%;
                height: 1px;
                background: var(--transparent-black-10);
            }
        }
    }

    &-filter {
        width: 90%;
        max-width: 271px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        user-select: none;

        &-btn {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--dark-grey);
            background: transparent;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;

            span {
                max-width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            svg {
                transform: rotate(180deg);
                transition: 0.3s;
            }

            &.border {
                border: 1px solid var(--transparent-black-10);
                border-radius: 6px;
                padding: 0 10px;
                box-sizing: border-box;
            }

            &.opened {
                svg {
                    transform: rotate(0deg);
                }
            }
        }

        &-options {
            width: 100%;
            top: 110%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 0;
            overflow: hidden;
            transition: 0.3s;

            &.opened {
                max-height: 500px;
                overflow-y: auto;
            }

            &-search {
                width: 100%;
                height: 35px;
                color: var(--transparent-black-60);
                border: 1px solid var(--transparent-black-10);
                background: transparent;
                padding: 10px 15px;
                box-sizing: border-box;
                border-radius: 6px;
                transition: 0.3s;
            }
        }

        &-option {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 10px 5px;
            box-sizing: border-box;
            border: 1px solid var(--transparent-black-10);
            border-radius: 6px;
            cursor: pointer;
            user-select: none;

            p {
                max-width: 50%;
            }

            input {
                position: relative;
                appearance: none;
                -webkit-appearance: none;
            }

            &-checkbox {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid var(--black);
                box-sizing: border-box;
                border-radius: 50%;
                margin-right: 5px;
                transition: 0.3s;

                span {
                    width: 65%;
                    height: 65%;
                    border-radius: 50%;
                    background: var(--black);
                    opacity: 0;
                    transition: 0.3s;
                }

                &.checked {
                    span {
                        opacity: 1;
                    }
                }
            }

            p {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@media (min-width: 901px) and (max-width: 1142px) {
    .filters-container {
        width: 30%;
    }
}

@media (max-width: 900px) {
    .filters {
        &-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: var(--background-color);
            padding: 75px var(--offset);
            box-sizing: border-box;
            overflow: auto;
            transform: translateX(-100%);
            z-index: 2;
            transition: 0.3s;

            &.active {
                transform: translateX(0);
            }

            p.upper.pre-small.black.bold {
                width: 90%;
                text-align: start;
            }
        }
    }
}
