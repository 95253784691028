.profile {
    &-section {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 50px;

        &-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: var(--top-padding) var(--offset) 0 var(--offset);
            box-sizing: border-box;
        }
    }

    &-settings {
        width: 100%;
        height: 100%;
        display: flex;

        &-sidebar {
            width: 20%;
            max-width: 268px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 51px;
            background: var(--profile-grey);
            padding: 32px 0 0 34px;
            box-sizing: border-box;

            &-categories {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                    color: var(--transparent-black-20);
                    cursor: pointer;
                    transition: 0.3s;

                    &:active {
                        color: var(--black);
                        font-weight: 500;
                    }
                }
            }
        }

        &-main {
            width: 100%;
            height: 100%;

            &-category {
                width: 100%;
                background: var(--profile-grey);
                padding: 28px 40px;
                box-sizing: border-box;
            }

            &-content {
                width: 50%;
                max-width: 416px;
                display: flex;
                flex-direction: column;
                gap: 25px;
                padding: 35px;
                box-sizing: border-box;

                &.no-limit {
                    width: 100%;
                    max-width: unset;
                }

                label {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }

                &-input {
                    height: 50px;
                    color: var(--transparent-black-60);
                    border: 1px solid var(--transparent-black-10);
                    padding: 10px 15px;
                    box-sizing: border-box;
                    border-radius: 6px;
                    transition: 0.3s;

                    &::placeholder {
                        text-transform: uppercase;
                        color: var(--transparent-black-60);
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                    gap: 35px;
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .profile-settings {
        position: relative;

        &-main-content {
            width: 100%;
        }

        &-sidebar {
            width: 100%;
            max-width: unset;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-100%);
            transition: 0.3s;

            &.active {
                transform: translateX(0);
            }

            &-button {
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--black);
                background: var(--transparent-black-10);
                border-radius: 0 6px 6px 0;
                cursor: pointer;
                z-index: 3;

                svg {
                    transform: rotate(90deg);
                    transition: 0.3s;
                }

                &.active {
                    left: unset;
                    right: 0;
                    border-radius: 6px 0 0 6px;

                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}

@media (hover: hover) {
    .profile-settings-sidebar-categories p:hover {
        color: var(--black);
        font-weight: 500;
    }
}
