.orders-order {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr) repeat(2, 2fr);
    grid-template-rows: 75px;
    border: 1px solid var(--transparent-black-20);
    border-radius: 6px;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;

    .cell {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 7px;
        overflow: hidden;

        &:nth-child(1) {
            grid-column: 1;
        }
        &:nth-child(2) {
            grid-column: 2;
        }
        &:nth-child(3) {
            grid-column: 3;
        }
        &:nth-child(4) {
            grid-column: 4;
        }
        &:nth-child(5) {
            grid-column: 5;
        }
        &:nth-child(6) {
            grid-column: 6;
        }

        p {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;

            &.start {
                align-self: flex-start;
            }
        }
    }

    .status {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
}

@media (max-width: 875px) {
    .orders-order {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .cell {
            display: flex;
            justify-content: unset;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .status {
            width: 10px;
            height: 10px;
        }
    }
}
