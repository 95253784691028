.password-modal {
    width: 471px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

@media (max-width: 600px) {
    .password-modal {
        width: 100%;
    }
}
