.loader {
    color: var(--black);
    box-sizing: border-box;
    animation: loading 3s infinite linear;
    user-select: none;
}

@keyframes loading {
    0% {
        opacity: 100;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}
