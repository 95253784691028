nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 99px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: 0.3s;
    z-index: 10;

    &.scrolled {
        color: var(--black);
        background: var(--background-color);
        border-bottom: 1px solid var(--transparent-black-10);
    }
}

.navbar {
    &-link {
        color: currentColor;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.3;
        transition: 0.3s;

        &-static {
            height: 80%;

            img {
                height: 100%;
            }
        }

        &.active {
            opacity: 1;
        }

        &:active {
            opacity: 1;
        }
    }

    &-category {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        box-sizing: border-box;

        &:nth-child(1) {
            justify-content: flex-start;
            padding: 0 0 0 var(--offset);
        }

        &:nth-child(3) {
            justify-content: flex-end;
            padding: 0 var(--offset) 0 0;
        }
    }

    &-button {
        position: fixed;
        width: 50px;
        height: 50px;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        background: var(--background-color);
        border-radius: 50%;
        padding: 5px;
        box-sizing: border-box;
        cursor: pointer;
        z-index: 11;
        transition: 0.3s;

        svg {
            width: 100%;
            height: 100%;
        }

        &.active {
            color: var(--white);
            background: var(--black);
        }

        &:active {
            color: var(--white);
            background: var(--black);
        }
    }
}

@media (max-width: 900px) {
    nav {
        height: 100vh;
        color: var(--black);
        background: var(--background-color);
        flex-direction: column;
        transform: translateX(-100%);

        &.active {
            transform: translateX(0);
        }
    }

    .navbar {
        &-link-static {
            max-width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 90%;
            }
        }

        &-category {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            box-sizing: border-box;

            &:nth-child(1),
            &:nth-child(3) {
                justify-content: center;
                padding: 0;
            }

            &:nth-child(2) {
                flex-direction: column;
            }
        }
    }
}

@media (hover: hover) {
    .navbar {
        &-link:hover {
            opacity: 1;
        }

        &-button:hover {
            color: var(--white);
            background: var(--black);
        }
    }
}
