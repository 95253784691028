@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
}

html {
    width: 100%;
    height: 100%;

    --white: #fbfbfb;
    --black: #000;
    --dark-grey: #1d1a1a;
    --blue: #3b5998;
    --red: #983b3b;
    --green: #54983b;
    --yellow: #c7b120;
    --transparent-black-10: rgba(0, 0, 0, 0.1);
    --transparent-black-20: rgba(0, 0, 0, 0.2);
    --transparent-black-40: rgba(0, 0, 0, 0.4);
    --transparent-black-60: rgba(0, 0, 0, 0.6);
    --profile-grey: #f7eec8;
    --background-color: #fff8dc;
    --offset: 100px;
    --block-gap: 200px;
    --top-padding: 200px;
}

html,
body,
#root,
.App {
    width: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    background: var(--background-color);
}

.App {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.upper {
    text-transform: uppercase;
}

.biggest {
    font-size: 4.75em;
}

.big {
    font-size: 3.25em;
}

.mid {
    font-size: 2.25em;
}

.pre-small {
    font-size: 1.125em;
}

.small {
    font-size: 0.9375em;
}

.extra-bold {
    font-weight: 700;
}

.bold {
    font-weight: 600;
}

.regular {
    font-weight: 400;
}

.thin {
    font-weight: 300;
}

p {
    padding: 0;
    margin: 0;
    color: var(--white);

    &.black {
        color: var(--black);
    }
}

.black {
    color: var(--black);
}

@media (max-width: 900px) {
    html {
        --top-padding: 75px;
    }
}

@media (min-width: 901px) and (max-width: 1399px) {
    html {
        --offset: 75px;
        --block-gap: 100px;
    }
}

@media (min-width: 401px) and (max-width: 900px) {
    html {
        --offset: 40px;
        --block-gap: 100px;
    }
}

@media (max-width: 400px) {
    html {
        --offset: 20px;
        --block-gap: 75px;
    }
}

@media (min-width: 651px) and (max-width: 1300px) {
    .biggest {
        font-size: 4.125em;
    }
}

@media (min-width: 461px) and (max-width: 650px) {
    .biggest {
        font-size: 3.5em;
    }

    .big {
        font-size: 2.8125em;
    }

    .mid {
        font-size: 1.75em;
    }
}

@media (min-width: 301px) and (max-width: 460px) {
    .biggest {
        font-size: 2.5em;
    }

    .big {
        font-size: 1.625em;
    }

    .mid {
        font-size: 1.25em;
    }

    .pre-small {
        font-size: 1.125em;
    }

    .small {
        font-size: 0.8125em;
    }
}

@media (max-width: 300px) {
    .biggest {
        font-size: 2em;
    }

    .big {
        font-size: 1.4375em;
    }

    .mid {
        font-size: 1.1875em;
    }

    .pre-small {
        font-size: 1.0625em;
    }

    .small {
        font-size: 0.75em;
    }
}
