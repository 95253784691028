.why-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 0 var(--offset);
    box-sizing: border-box;

    &-features {
        $featuresCountInARow: 3;
        display: flex;
        align-items: center;
        gap: 150px;
        flex-wrap: wrap;
    }

    &-feature {
        width: 276px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 20px 0;
        box-sizing: border-box;

        .small {
            color: var(--transparent-black-60);
        }
    }
}

@media (max-width: 900px) {
    .why-us {
        text-align: center;

        &-features {
            gap: 50px;
            justify-content: center;
            flex-direction: column;
        }

        &-feature {
            width: 100%;
            gap: 15px;
        }
    }
}
