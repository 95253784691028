.cart-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--transparent-black-20);
    z-index: 100;

    &-inner {
        width: 100%;
        height: 100%;
        max-height: 70vh;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        &-wrapper {
            width: 90%;
            max-width: 863px;
            max-height: 80vh;
            background: var(--background-color);
            border-radius: 10px;
            padding: 25px 35px;
            box-sizing: border-box;
            overflow: hidden;
        }

        &-products {
            display: flex;
            flex-direction: column;
            gap: 25px;

            &-container {
                max-height: 50%;
                display: flex;
                flex-direction: column;
                gap: 25px;

                &.centered {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }

        &-payment {
            width: 100%;
            max-width: 274px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            box-sizing: border-box;
            border-radius: 6px;
        }

        &-buttons {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }

        &-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                color: var(--black);
                stroke-width: 2px;
                cursor: pointer;
                transition: 0.3s;

                &:active {
                    color: var(--red);
                }
            }
        }
    }
}

@media (max-width: 740px) {
    .cart-modal-inner {
        &-payment {
            width: 100%;
        }

        &-buttons {
            align-items: center;
            flex-direction: column;
            gap: 20px;
        }
    }
}

@media (hover: hover) {
    .cart-modal-inner-header svg:hover {
        color: var(--red);
    }
}
