.page {
    width: 100vw;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--block-gap);

    &.bottom {
        margin-bottom: 100px;
    }

    &.centered {
        align-items: center;
        justify-content: center;
    }
}
