.contacts-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: var(--top-padding) var(--offset) 0 var(--offset);
    box-sizing: border-box;
    gap: 25px;

    iframe {
        width: 40%;
        aspect-ratio: 60/45;
    }

    .info {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    p {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            text-align: end;
            cursor: pointer;
        }
    }
}

@media (max-width: 1150px) {
    .contacts-section p,
    .contacts-section p span {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 815px) {
    .contacts-section {
        align-items: center;
        flex-direction: column;

        iframe,
        .info {
            width: 90%;
        }
    }
}
