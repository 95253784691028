.notifications {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    pointer-events: none;
    user-select: none;
    z-index: 100;
}

.notification {
    position: relative;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: var(--background-color);
    border: 1px solid rgba(72, 48, 24, 0.04);
    color: var(--black);
    right: -110%;
    box-sizing: border-box;
    padding: 0 0 0 50px;
    border-radius: 6px;
    flex-shrink: 0;
    animation: notificationAppear forwards;

    svg {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 10px;
    }
}

@keyframes notificationAppear {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(-110%);
    }

    85% {
        transform: translateX(-110%);
    }
    100% {
        transform: translateX(0);
    }
}

@media (max-width: 560px) {
    .notifications {
        width: 95%;
        height: 80px;
        max-width: unset;
        align-items: unset;
        top: auto;
        bottom: 0;
        left: 50%;
        right: unset;
        overflow-y: hidden;
        overflow-x: hidden;
        transform: translateX(-50%);
    }

    .notification {
        width: 100%;
    }
}
