.path-text {
    display: flex;
    align-items: center;
    gap: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a,
    span {
        text-decoration: none;
        color: var(--transparent-black-10);
        transition: 0.3s;

        &:last-of-type {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    a.black {
        color: var(--black);
    }

    a:not(.black):active {
        color: var(--black);

        & ~ a.black {
            color: var(--transparent-black-10);
        }
    }
}

@media (hover: hover) {
    .path-text a:not(.black):hover {
        color: var(--black);

        & ~ a.black {
            color: var(--transparent-black-10);
        }
    }
}
