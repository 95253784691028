.password-input-container {
    position: relative;

    .password-toggle {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.input-field {
    width: 100%;
    height: 50px;
    color: var(--transparent-black-60);
    border: 1px solid var(--transparent-black-10);
    background: transparent;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 6px;
    transition: 0.3s;

    &::placeholder {
        text-transform: uppercase;
        color: var(--transparent-black-60);
        max-width: 85%;
    }

    &.invalid {
        border-color: var(--red);

        &::placeholder {
            color: var(--red);
        }
    }

    &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &-label {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        &.checkbox {
            justify-content: flex-start;

            input {
                position: relative;
                appearance: none;
                -webkit-appearance: none;
            }

            .checkbox-wrapper {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid var(--black);
                box-sizing: border-box;
                border-radius: 50%;
                margin-right: 5px;
                transition: 0.3s;

                span {
                    width: 65%;
                    height: 65%;
                    border-radius: 50%;
                    background: var(--black);
                    opacity: 0;
                    transition: 0.3s;
                }

                &.checked {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-phone {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        &-article {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--transparent-black-10);
            color: var(--dark-grey);
            border-radius: 6px;
            padding: 10px;
            box-sizing: border-box;
            aspect-ratio: 1/1;
            user-select: none;
        }
    }

    &-error {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: start;
        color: var(--red);
    }
}
