.about-us {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 var(--offset);
    box-sizing: border-box;

    &-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    &-image {
        width: 40%;
        height: 100%;
        background-image: url("../../../../assets/AboutUs.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media (max-width: 900px) {
    .about-us {
        height: auto;
        flex-direction: column;
        gap: 50px;

        &-text,
        &-image {
            width: 100%;
        }

        &-image {
            min-height: 25vh;
        }
    }
}
