.auth {
    &-section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 50px;
        padding: var(--top-padding) var(--offset) 0 var(--offset);
        box-sizing: border-box;
        text-align: center;
    }

    &-errors {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
            width: fit-content;
            color: var(--red);
        }
    }

    &-modal {
        width: 520px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 50px;

        &-inputs {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .forgot-password {
                width: fit-content;
                font-size: 0.75em;
                text-align: start;
                cursor: pointer;
            }

            &-wrapper {
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 35px;

                span {
                    width: 100%;
                    height: 1px;
                    background: var(--transparent-black-20);
                }
            }

            &-devided {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 15px;
            }
        }
    }
}

@media (max-width: 600px) {
    .auth-modal {
        width: 100%;

        &-inputs {
            &-wrapper {
                width: 100%;
            }
            &-devided {
                width: 100%;
                display: flex;
                justify-content: unset;
                flex-direction: column;
                gap: 15px;
            }
        }
    }
}
