.checkout {
    $innerGap: 15px;

    &-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 100px;
        padding: var(--top-padding) var(--offset) 0 var(--offset);
        box-sizing: border-box;

        &-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                width: 50%;
            }
        }

        &-inner {
            display: flex;
            justify-content: space-between;
            gap: 150px;

            &-inputs {
                width: 25%;
                display: flex;
                flex-direction: column;
                gap: 50px;

                &-group {
                    display: flex;
                    flex-direction: column;
                    gap: $innerGap;
                }
            }

            &-products {
                width: 65%;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: calc($innerGap * 1.5);

                &-checkout {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: $innerGap;

                    p {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .checkout-section {
        &-header {
            justify-content: unset;
            align-items: center;
            flex-direction: column;
            gap: 15px;

            p {
                width: 100%;
                text-align: center;
            }
        }
        &-inner {
            align-items: center;
            flex-direction: column-reverse;

            &-inputs {
                width: 50%;
                align-items: center;

                &-group {
                    width: 100%;
                }
            }

            &-products {
                width: 100%;

                &-checkout {
                    p {
                        font-size: 0.9375em;
                    }
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .checkout-section-inner-inputs {
        width: 100%;
    }
}

@media (max-width: 370px) {
    .checkout-section-inner-inputs {
        width: 100%;
    }

    .checkout-section-inner-products-checkout p {
        font-size: 0.75em;
    }
}
