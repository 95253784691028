.buy-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: var(--top-padding) var(--offset) 0 var(--offset);
    box-sizing: border-box;

    &-text {
        display: flex;
        align-items: center;
        gap: 2px;
        color: var(--transparent-black-60);
    }

    &-inner {
        width: 100%;
        display: flex;
        gap: 50px;
    }
}
