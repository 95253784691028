.product {
    $totalGap: 50px;

    &-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $totalGap;
        padding: var(--top-padding) var(--offset) 0 var(--offset);
        box-sizing: border-box;
    }

    &-info {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: $totalGap;

        &-main {
            width: 100%;
            display: flex;
            gap: $totalGap;

            &-image {
                position: relative;
                width: 50%;
                height: 667px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-sizing: border-box;

                img {
                    max-height: 90%;
                    max-width: 100%;
                }

                .sku {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                }
            }

            &-description {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: $totalGap;

                &-button {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }

                &-principal {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    p {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &-options {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;
                }
            }
        }

        &-additional {
            width: 100%;
            display: flex;
            gap: $totalGap;

            &-opportunities {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            &-decoration {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            &-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }
}

@media (max-width: 900px) {
    .product {
        &-info {
            &-main {
                align-items: center;
                flex-direction: column;

                &-image {
                    max-width: 100%;
                    height: auto;
                    max-height: 400px;
                    aspect-ratio: 1/2;

                    img {
                        max-width: 100%;
                    }
                }

                &-description {
                    width: 100%;

                    &-options {
                        justify-content: flex-start;

                        .filters-filter {
                            width: 100%;
                        }
                    }
                }
            }

            &-additional {
                flex-direction: column;

                &-opportunities {
                    width: 100%;
                }

                &-decoration {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .product-info-main-description-principal {
        width: 100%;
    }
}

@media (max-width: 312px) {
    .product-info-main-description-options {
        justify-content: center;
    }
}
