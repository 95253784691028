footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-color);
    padding: 50px 0;
    gap: 50px;
    grid-row: 3;
    border-top: 1px solid var(--transparent-black-10);

    .phone {
        cursor: pointer;
    }
}

@media (max-width: 400px) {
    footer {
        text-align: center;
        flex-direction: column;
    }
}
