.order {
    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 150px 20px 0 20px;
        box-sizing: border-box;

        .header {
            width: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }

    &-inner-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &-info {
        width: 100%;
        max-width: 40%;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &-items {
        width: 100%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}

@media (max-width: 1260px) {
    .order {
        &-inner-wrapper {
            flex-direction: column;
            justify-content: center;
            gap: 50px;
        }

        &-items,
        &-info {
            max-width: 100%;
        }
    }
}
