.buy-products {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 50px;
        flex-wrap: wrap;
    }

    &-pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &-button {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--transparent-black-10);
            color: var(--dark-grey);
            border-radius: 6px;
            padding: 10px;
            box-sizing: border-box;
            aspect-ratio: 1/1;
            cursor: pointer;
            user-select: none;
            transition: 0.3s;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &.active {
                background: var(--dark-grey);
                color: var(--white);
            }

            &:active:not(.disabled) {
                background: var(--dark-grey);
                color: var(--white);
            }
        }
    }
}

@media (max-width: 900px) {
    .buy-products {
        width: 100%;
    }
}

@media (hover: hover) {
    .buy-products-pagination-button:hover:not(.disabled) {
        background: var(--dark-grey);
        color: var(--white);
    }
}
